<template>
  <div class='invoice'>
    <van-nav-bar
      title="补开发票"
      left-arrow
      @click-left="onClickLeft"
      fixed
      :z-index="100"
    />
    <section>
      <!-- 头部排版 -->
    <div class="commodity" v-for="index in commodity.skuList" :key="index.id">
        <div class="commodity-left">
            <img class="commodity-img" :src="index.img" alt="">
        </div>
        <div class="commodity-right">
            <div class="commodoty-orderNumber">
                <div class="commodoty-orderNumber-text">订单编号</div>
                <div>{{commodity.orderNo}}</div>
            </div>
            <div class="commodity-invoiceAmount">
                <div class="commodity-invoiceAmount-text">开票金额</div>
                <div class="commodity-invoiceAmount-price">￥{{(index.price/100).toFixed(2)}}</div>
            </div>
        </div>
    </div>
   <div class="content">

        <van-form @submit="onSubmit">
            <div  class="content-text">
            <van-cell-group inset>
            <!-- <van-cell title="发票类型" value="增值税电子普通发票" v-model="formData.invoiceType"/> -->
            <!-- <van-field
                
                input-align="right"
                v-model="formData.invoiceType"
                name="发票类型"
                label="发票类型"
                readonly
            /> -->
            
            <van-field name="radio" label="抬头类型" input-align="right" :rules="[{ required: true, message: '请选择抬头类型' }]">
                <template #input >
                <van-radio-group  v-model="formData.invoiceType" direction="horizontal" >
                    <van-radio @click="geren(formData.invoiceType)" name="1">个人</van-radio>
                    <van-radio @click="qiye(formData.invoiceType)" name="2">企业</van-radio>
                </van-radio-group>
                </template>
            </van-field>

            <!-- <van-field name="radio" label="发票类容" input-align="right">
                <template #input >
                <van-radio-group  v-model="formData.invoiceTypeContent" direction="horizontal" >
                    <van-radio name="0">商品明细</van-radio>
                </van-radio-group>
                </template>
            </van-field> -->

            <van-field
                input-align="right"
                v-model="formData.invoice"
                name="发票抬头"
                label="发票抬头"
                placeholder="请输入发票抬头"
                :rules="[{ required: true}]"
            />

            <van-field
                v-if="formData.invoiceType == 2"
                input-align="right"
                v-model="formData.taxPayer"
                name="单位税号"
                label="单位税号"
                placeholder="请输入单位税号"
                :rules="[{ required: true}]"
            />

            <van-field
                input-align="right"
                v-model="formData.receiverName"
                name="收件人"
                label="收件人"
                placeholder="请输入收件人"
                :rules="[{ required: true}]"
            />

            <van-field
                input-align="right"
                v-model="formData.receiverMobile"
                :maxlength="11"
                name="收件人电话"
                label="收件人电话"
                placeholder="请输入收件人电话"
                :rules="[{ required: true}]"
            />

            <van-field
                input-align="right"
                v-model="formData.receiverEmail"
                name="收件人邮箱"
                label="收件人邮箱"
                placeholder="请输入收件人邮箱"
                :rules="[{ required: true}]"
            />

            <van-field
                v-show="showOpen"
                v-if="formData.invoiceType == 2"
                input-align="right"
                v-model="formData.bank"
                name="开户银行"
                label="开户银行"
                placeholder="请输入开户银行/选填"
            />

            <van-field
                v-show="showOpen"
                v-if="formData.invoiceType == 2"
                input-align="right"
                v-model="formData.bankNumber"
                name="银行账号"
                label="银行账号"
                placeholder="请输入银行账号/选填"
            />

            <van-field
                v-show="showOpen"
                v-if="formData.invoiceType == 2"
                input-align="right"
                v-model="formData.registeredAddress"
                name="企业地址"
                label="企业地址"
                placeholder="请输入企业地址/选填"
            />

            <van-field
                v-show="showOpen"
                v-if="formData.invoiceType == 2"
                input-align="right"
                v-model="formData.registeredMobile"
                name="企业电话"
                label="企业电话"
                placeholder="请输入企业电话/选填"
            />
            
                <van-field
                v-show="showOpen"
                input-align="right"
                v-model="formData.remark"
                rows="2"
                autosize
                label="备注"
                type="textarea"
                maxlength="200"
                placeholder="请输入备注/选填"
                show-word-limit
                />
        
            </van-cell-group>
            <div class="icon-img" @click="clickOpen" v-show="!showOpen">
            <img src="@/assets/images/open.png" alt="">
            </div>
            <div class="icon-img" @click="clickPutAway" v-show="showOpen">
            <img src="@/assets/images/putAway.png" alt="">
            </div>
            </div>
            <div class="submitBtn">
            <van-button round block type="danger" native-type="submit">
                提交
            </van-button>
            </div>
        </van-form>
    </div>
    </section>
  </div>
</template>

<script>
import Btn from '@/components/common/btn.vue'
export default {
  name: '',
  components: {
    Btn,
  },

  data () {
    return {
       formData: {
        invoiceType: "1",//抬头类型
        // invoiceTypeContent:"0",
        invoice:"",//发票抬头
        taxPayer:"",//单位税号
        bank:"",//开户银行
        bankNumber:"",//银行账号
        registeredAddress:"",//企业地址
        registeredMobile:"",//企业电话
        // invoiceType:"增值税电子普通发票",
        remark:"",//备注
        receiverName:"",//收件人
        receiverMobile:"",//收件人电话
        receiverEmail:"",//收件人邮箱
      },
       commodity:{},
    showOpen:false,
    
    }
  },
  created () { 
      this.commodity = JSON.parse(localStorage.getItem("commodity"))
      console.log(this.commodity);
      this.getInvoiceUserDefultGet();
  },
  mounted () { },
  methods: {
    // 返回
    onClickLeft() {
      this.$router.back(0);
    },
    onSubmit(){
    //   console.log(this.formData);
      let money = this.commodity.actualPrice / 100;
      let order_no = this.commodity.orderNo;
      let sku_List = this.commodity.skuList;
      let newArr = [];
      sku_List.forEach(item => {newArr.push(item.spuTitle,item.title,"×",item.num," ")});
      function clear(str) { 
        str = str.replace(/,/g, "");//取消字符串中出现的所有逗号 
        return str; 
      }
      let details = clear(newArr.toString())
      this.formData = {
          ...this.formData,
          money:money,
          order_no:order_no,
          details: details,
      }
      console.log(this.formData);

    //   发送补开发票接口
    // return
      this.$api.my.RepairInvoice({
          userId: localStorage.getItem("customerId"),
          invoiceDO: JSON.stringify(this.formData),
      }).then((res) =>{
          if (res.errno === 200) {
              this.$toast("提交成功")
              this.$router.push("/myorder")
              localStorage.removeItem("commodity")
          }else{
              this.$toast("提交失败")
          }
      })
      
      
    },
    clickOpen(){
      this.showOpen = true;

    },
    clickPutAway(){
      this.showOpen = false;
    },
    // 回填数据
    getInvoiceUserDefultGet(){
      this.$api.order.getInvoiceUserDefult({
        accessToken: localStorage.getItem("token"),
        invoiceType: this.formData.invoiceType,
      }).then((res) =>{
        if (!res.data) {
          // console.log("没有默认数据");
          return;
          
        }else{
          
          // console.log(res,"发票默认接口");
          this.formData = res.data;
        }
      })
    },
     // 根据单选框回填数据
    geren(invoiceType){
      // console.log(this.formData,"个人");

      // return;
      // console.log(invoiceType,"geren");
      this.$api.order.getInvoiceUserDefult({
        accessToken: localStorage.getItem("token"),
        invoiceType: this.formData.invoiceType,
      }).then((res) =>{
        if (!res.data) {
          this.formData.invoice = "";
          this.formData.receiverName = "";
          this.formData.receiverMobile = "";
          this.formData.receiverEmail = ""
          return;
        }else{
          // console.log(res);
        let list = res.data;
        // for(let obj in this.formData){
        //   if(!this.formData.obj){
        //       this.formData.obj = list.obj
        //   }
        // }
        this.formData = {
          ...this.formData,
          invoiceType:list.invoiceType,
          invoice:list.invoice,
          receiverName:list.receiverName,
          receiverMobile:list.receiverMobile,
          receiverEmail:list.receiverEmail,
        }
        }
      })
    },
    qiye(invoiceType){
      // console.log(this.formData,"企业");
    
      // return;
      // console.log(invoiceType,"qiye");
      this.$api.order.getInvoiceUserDefult({
        accessToken: localStorage.getItem("token"),
        invoiceType: this.formData.invoiceType,
      }).then((res) =>{
        if (!res.data) {
          this.formData.invoice = "";
          this.formData.receiverName = "";
          this.formData.taxPayer = "";
          this.formData.receiverMobile = "";
          this.formData.receiverEmail = ""
          return;
        }else{
          // console.log(res);
        let list = res.data;
        //  for(let obj in this.formData){
        //   if(!this.formData.obj){
        //       this.formData.obj = list.obj
        //   }
        // }
        this.formData = {
          ...this.formData,
          invoiceType:list.invoiceType,
          invoice:list.invoice,
          taxPayer:list.taxPayer,
          receiverName:list.receiverName,
          receiverMobile:list.receiverMobile,
          receiverEmail:list.receiverEmail,
        }
        }
      })
    },
  },
  computed: {},
  watch: {},
}
</script>
<style lang="less" scoped>
@import "../../../assets/styles/element.less";
.invoice {
  width: 100vw;
  height: 100vh;
  background: #f7f7f7;
  padding: .28rem .3rem;
  box-sizing: border-box;
}
section {
  position: relative;
  top: 46px;
  height: calc(100vh - 46px);
  display: flex;
//   justify-content: center;
  flex-direction: column;
  align-items: center;
 .commodity{
        box-sizing: border-box;
        margin-top: .22rem /* 11/50 */;
        width: 100%;
        background: #FFFFFF;
        padding: .3rem /* 15/50 */ 0 .3rem /* 15/50 */ .3rem /* 15/50 */;
        display: flex;

        box-shadow: 0px 0px .15rem 0px rgba(0, 0, 0, 0.05);
        border-radius: .2rem;
        .commodity-left{
            .commodity-img{
                width: 1rem /* 50/50 */;
                height: 1rem /* 50/50 */;
                display:block;
                margin-right: .2rem /* 10/50 */;
            }
        }
        .commodity-right{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .commodoty-orderNumber{
                display: flex;
                font-size: 13px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 18px;
                .commodoty-orderNumber-text{
                    margin-right: .18rem /* 9/50 */;
                }
            }
            .commodity-invoiceAmount{
                display: flex;
                font-size: 13px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 18px;
                .commodity-invoiceAmount-text{
                    margin-right: .18rem /* 9/50 */;
                }
                .commodity-invoiceAmount-price{
                    color: red;
                    font-size: .32rem /* 16/50 */;
                }
            }
        }
        
    }
  .content{
    width: 100%;
    position: relative;
    padding-top: .22rem /* 11/50 */;
    // border-radius: 10px !important;
    
   
    ::v-deep .van-cell{
          left: 0;
          right: 0;
      .van-radio-group--horizontal{  
        .van-radio--horizontal{
          margin-right: 0;
          margin-left: .24rem /* 12/50 */;
        }
      }
      .van-field__label{
        color: #333;
      }
      .van-radio__icon--checked .van-icon{
        background-color: #C83F3F;
        border-color: #C83F3F;
      }
    }
      .van-button--danger{
        // background-color: #C83F3F;
        // border: 1px solid #C83F3F;
        background: linear-gradient(0deg, #EE3A3A, #F46161);
        border-radius: .1rem;
        font-size: .36rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: .36rem;
      }
    .icon-img{
      height: .88rem /* 44/50 */;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      // margin-bottom: .4rem /* 20/50 */;
      img{
        width: .48rem /* 24/50 */;
        height: .48rem /* 24/50 */;
      }
    }
  }
}
.submitBtn{
  position:fixed;
  bottom: .2rem /* 10/50 */;
  left: 0;
  width: 91%;
  margin: 0 .32rem /* 16/50 */ .32rem /* 16/50 */ .32rem /* 16/50 */;
  .btn {
    width: 100%;
      .btnmin(
        @padding:.24rem /* 12/50 */ 0,
        @radius:.64rem /* 32/50 */,
        @size:.32rem /* 16/50 */,
        @bgcolor:#c83f3f,
        @color:#fff
      );
    }

}
.content-text{
  // height: 60vh;
  overflow-y: scroll;
   box-shadow: 0px 0px .15rem 0px rgba(0, 0, 0, 0.05);
        border-radius: .2rem;
        background-color: #fff;
}
</style>
